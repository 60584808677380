/**=====================
     38. Landing CSS Start
==========================**/
.tap-top {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 99;
  color: white;
  text-align: center;
  background: linear-gradient(to top, $primary-color, $info-color);
  border-radius: 100%;
  font-size: 22px;
  cursor: pointer;
  line-height: 2.2;
  display: none;
  border: 1px solid white;
}

.landing-main {
  .landing-home{
    background-image: url(/assets/images/landing/home/main_bg.jpg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    .landing-left{
      height: 100%;
      display: flex;
      align-items: center;
      h5{
        margin-bottom: 20px;
        line-height: 1.5;
      }
      h1{
        font-weight: 900;
        margin-bottom: 30px;
      }
      p{
        font-size: 15px;
        margin-bottom: 25px;
      }
      .button-bottom{
        .btn-outline-primary-2x{
          background-color: $white;
        }
      }
    ;
    }
    .landing-body{
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
    }
  }

  .live-box{
    padding: 8px;
    background-color: $white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    position: relative;
    &:hover{
      &:before{
        opacity: 1;
        transform: scale(1);
        transition: all 0.3s ease;
      }
      .btn{
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
    &:before{
      opacity: 0;
      content: "";
      position: absolute;
      background-color: rgba(255, 255, 255, 0.20);
      z-index: 2;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      transform: scale(0);
      transition: all 0.3s ease;
    }
    .btn{
      position: absolute;
      top: 45%;
      left: 30%;
      opacity: 0;
      transition: all 0.3s ease;
      z-index: 3;
    }

  }
  .gray-section-bg {
    background-color: #f9f9f9;
  }
  .navbar-expand-lg {
    .navbar-brand {
      padding-left: 18px;
    }
    .navbar-collapse {
      flex-flow: row nowrap;
      justify-content: flex-end;
      .navbar-nav {
        .nav-item {
          a {
            color: $white;
            text-transform: uppercase;
            padding: 20px;
            font-weight: 600;
            opacity: 0.8;
            transition: all 0.3s ease;
            &:hover {
              opacity: 1;
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }
  .section-space {
    padding: 80px 0;
    text-align: center;
    p {
      font-size: 15px;
    }
  }
  .landing-header {
    h2 {
      text-transform: uppercase;
      margin-bottom: 20px;
      font-weight: 700;
      color: $dark-card-background;
    }
    .line{
      position: relative;
      height: 3px;
      width: 70px;
      background-color: $info-color;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      margin-bottom: 60px;
    }
  }
  .admin-options {
    .live-demo-btn {
      margin-top: 30px;
      margin-bottom: 70px;
      a {
        text-transform: uppercase;
        color: $white;
        &:focus {
          box-shadow: none;
        }
      }
    }

  }
  .updates-features {
    .row {
      justify-content: center;
    }
    .landing-header {
      h2 {
        margin-bottom: 20px;
      }
      p {
        color: #828282;
        margin: 0 auto;
        padding-bottom: 20px;
      }
    }
    h6 {
      margin-top: 30px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  .advance-bg {
    background-image: url(/assets/images/landing/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    background-position: center;
  }
  .application-section {
    .nav-pills {
      justify-content: center;
      border-bottom: 0px !important;
      .nav-item {
        position: relative;
        .nav-link {
          font-size: 17px;
          padding: 12px 30px;
          line-height: 1;
        }
        a {
          color: $black;
          &.active {
            color: $white;
            background-color: $info-color;
            &:before {
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 7px solid $info-color;
              content: "";
              bottom: -7px;
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;
              z-index: 2;
            }
            &:after {
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 7px solid $info-color;
              content: "";
              bottom: -7px;
              position: absolute;
              margin: 0 auto;
              left: 0;
              right: 0;
              z-index: 1;
            }
          }
        }

      }
      .application-icons {
        i {
          font-size: 24px;
          margin-right: 0;
        }
      }
    }
    .tab-content {
      margin-top: 30px;
      img {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        border: 1px solid #f1f1f1;
      }
      &.active {
        -webkit-animation: fadeInDown 0.3s linear;
        animation: fadeInDown 0.3s linear;
      }
    }
    .landing-header {
      h2 {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
        color: #828282;
      }
    }
  }
  .advance-widget {
    background-color: #fafafa;
    .widgets-section {
      background-image: url(/assets/images/landing/widgets-bck.png);
      background-size: cover;
      height: 600px;
      animation: widget-animation 110s linear infinite reverse;
      -webkit-animation: widget-animation 110s linear infinite reverse;
      background-repeat: repeat-x;
    }
    @keyframes widget-animation {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: 1920px;
      }
    }
  }
  .key-features-section {
    .feature-main {
      padding: 0 25px 25px 25px;
      .feature-icon{
        width: 50px;
        margin: 0 auto;
      }
      .feature-content {
        margin-top: 35px;
        h5 {
          font-weight: 600;
          margin-bottom: 15px;
        }
        p {
          color: #828282;
        }
      }
    }
  }
  .many-feature-section {
    h5 {
      text-align: left;
      margin-bottom: 20px;
      span {
        margin-left: 15px;
      }
    }
  }
  .exclusive-features {
    .container{
      .row {
        &:last-child {
          margin-bottom: 0;
        }
      }
      >.row{
        &:nth-child(odd){
          .inner-block {
            background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #f3f3f3);
          }
        }
        .inner-block {
          height: calc(100% - 30px);
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #f3f3f3);
          padding: 50px;
          margin-bottom: 50px;
        }
      }
    }
    background-image: url("/assets/images/landing/home/pattern.jpg");
  }
  .landing-components {
    i {
      font-size: 35px;
      background-color: #fff;
      padding: 25px;
      border-radius: 50%;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
    }
    p {
      font-size: 14px;
    }
    h4 {
      text-transform: capitalize;
      margin-bottom: 15px;
    }
    .tour-mb {
      margin-bottom: 2px;
      font-weight: 600;
    }
  }
  .support-section{
    i{
      font-size: 45px;
      background-color: #fff;
      padding: 25px;
      border-radius: 50%;
      box-shadow: 3px 3px 4px rgba(0,0,0,0.2);
    }
    h6{
      margin-top: 40px;
      font-weight: 600;
    }
    p{
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  .cd-timeline-content{
    .cd-date {
      text-align: left;
    }
  }
  .cd-timeline-img i {
    left: 13%;
  }
  .footer-bg {
    text-align: center;
    background-color: $info-color;
    background: linear-gradient(to right, rgb(159, 117, 236) 0%, #7183ef 100%);
    h2 {
      font-size: 36px;
      text-transform: uppercase;
      color: $white;
      font-weight: 700;
      letter-spacing: 1px;
    }
    .star-rate {
      margin: 40px 0;
      i {
        font-size: 45px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    button {
      text-transform: uppercase;
    }
  }
}
.customizer-img{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid #f1f1f1;
}

/**=====================
      document css
==========================**/

.document {
  &.page-wrapper {
    .page-sidebar {
      top: 100px;
      padding: 30px 20px;
    }
    .page-body-wrapper {
      .page-body {
        margin-top: 130px;
      }
    }
  }
  .particles-headline {
    background-position: top;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background-image: linear-gradient(-90deg, $dark-sky, $white);
  }
}

.page-wrapper{
  &.document{
    .page-body{
      min-height: unset;
    }
  }
}
.document-content {
  ul.nav-pills {
    overflow-y: scroll;
    max-height: calc(100vh - 180px);
    display: block;
    li {
      line-height: 25px;
      .nav-link {
        border-radius: .25rem;
        font-size: 15px;
        display: block;
        text-decoration: none;
        line-height: 40px;
        color: $color-scarpa;
        font-weight: 500;
        padding: 0;
        i {
          margin-top: 3px;
          text-decoration: none;
          line-height: 25px;
          color: $primary-color;
          margin-right: 20px;
        }
        &.active {
          background-color: unset !important;
          color: $primary-color;
        }
      }
      .show > .nav-link {
        background-color: unset !important;
        color: $primary-color;
      }
    }
  }
  .table {
    thead {
      th {
        width: 50%;
      }
    }
  }
  .set-flex {
    display: flex;
  }
  i {
    &.fa-star {
    color: $btn-search-bg-c;
  }
}
  .col-9 {
    padding-left: 40px;
    .set-height {
      overflow: scroll;
      overflow-x: hidden;
    }
    .tab-content {
      overflow: auto;
      overflow-x: hidden;
      max-height: calc(100% - 200px);
      position: fixed;
      width: 976px;
      margin-left: 30px;
      -webkit-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      -o-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      .section-head {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 40px;
        padding: 0;
      }
    }
  }
  .card-body.tree-color {
    padding: 0;
  }
  .jstree-default {
    padding: 30px;
    background-color: $dark-card-background;
    box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
    .jstree-anchor {
      color: #a6aaaf;
    }
    .jstree-hovered {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .jstree-clicked {
      color: white !important;
      background: transparent !important;
    }
    .jstree-icon {
      color: #656b78 !important;
    }
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .html-editor {
    color: rgba(255, 255, 255, 0.7);
    background-color: #2b2b2b;
    pre {
      color: rgba(255, 255, 255, 0.7);
      background-color: #000;
    }
  }
  .progress {
    margin-bottom: 10px;
  }
  ul.list-ticked li {
    margin-bottom: 10px;
    padding-left: 1rem;
  }
  &.faq {
    font-size: 18px;
    .btn {
      font-size: 18px;
      font-weight: 600;
      color: $primary-color;
      &.btn-link {
        color: $black;
        &.collapsed {
          color: $primary-color;
        }
      }
    }
    ul {
      border: unset;
      &.nav {
        display: block;
        li .nav-link {
          border-radius: .25rem;
          font-size: 18px;
          display: block;
          padding: 3px 20px 3px 30px;
          text-decoration: none;
          line-height: 30px;
          color: $color-scarpa;
          font-weight: 500;
          border: unset;
          i {
            margin-top: 3px;
            text-decoration: none;
            line-height: 25px;
            color: $primary-color;
            margin-right: 20px;
          }
          &.active {
            color: $primary-color;
            background-color: unset;
            border: unset;
          }
          &:hover {
            border: unset;
          }
        }
      }
    }
  }
  .reviews{
    background-color: $white;
    padding: 30px 0;
    margin-bottom: 30px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
    h1{
      text-transform: uppercase;
      font-weight: 600;
    }
    h5{
      text-transform: capitalize;
    }
  }
}

/**=====================
      document css end
==========================**/

/**=====================
     38. Landing CSS Ends
==========================**/
