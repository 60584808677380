html, body {
  font-family: 'Open Sans' !important;
}
/* #search-bar-0.form-control{
  margin-bottom: 5px;
}

.card > .card-header {
  padding: 20px 30px;
}

.card > .card-body { 
  padding-top: 10px;
} */
.css-692fgp{
  background-color: #00c292 !important;
}

.css-vcfpfv{
  background-color: #008f6c !important;
}

.css-1b3lipl::before{
  background-color: #008f6c !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  height: 200px;
}